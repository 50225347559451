// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // defaultauth: 'fackbackend',
  defaultauth: 'firebase',
  firebaseConfig: {
    apiKey: "AIzaSyDpJf4MJ2yJkIwN5ABhuh3uS43vtWp3C5o",
    authDomain: "cartillas-app.firebaseapp.com",
    projectId: "cartillas-app",
    storageBucket: "cartillas-app.appspot.com",
    messagingSenderId: "100025132039",
    appId: "1:100025132039:web:b2125b3940d2f70fe476a2",
    measurementId: "G-4RTV9NNZ5Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
