<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        2024 © HLF Romero.
      </div>
      <div class="col-sm-6">
        <div class="text-sm-end d-none d-sm-block">
          Hecho con <i class="mdi mdi-head-snowflake-outline text-danger"></i>.
        </div>
      </div>
    </div>
  </div>
</footer>